var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"h-100 basic-layout"},[_c('Header'),_c('div',{staticClass:"content-section",class:{
        'is-not-home': !_vm.isHome,
         'has-no-footer': !_vm.showFooter,
         'is-home': _vm.isHome,
         'is-live-room': _vm.isLiveRoom,
         'is-competition': _vm.isCompetition,
    }},[_c('router-view',{staticClass:"content-router"})],1),(_vm.showFooter)?[_c('Footer')]:_vm._e(),(_vm.isHome)?_c('SideBar'):_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }