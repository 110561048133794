import { render, staticRenderFns } from "./IconPng.vue?vue&type=template&id=563c4f58&scoped=true"
import script from "./IconPng.vue?vue&type=script&lang=js"
export * from "./IconPng.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "563c4f58",
  null
  
)

export default component.exports