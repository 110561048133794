var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"full-width",class:{
    'is-light-header': _vm.isLightHeader,
     'host-page': _vm.isHost,
     'p-fixed': _vm.isLightHeader || _vm.isCompetition,
     'can-hover': _vm.opacityHeader,
     'is-live-room ': _vm.isLiveRoom || _vm.isCompetition,
     'text-logo': _vm.isCompetition,
     'live-room': _vm.isLiveRoom
    },style:(_vm.isLightHeader ? _vm.homeHeader : _vm.headerStyle)},[_c('div',{staticClass:"p-relative header flex justify-between align-center",class:{
        'wrap-1200': !_vm.isHost && !_vm.isLiveRoom
    }},[_c('div',{staticClass:"left-section flex align-center"},[_c('div',{staticClass:"logo flex align-center",class:{
                'is-normal': _vm.isLiveRoom || _vm.isHost
            }},[_c('div',{staticClass:"bg-no-repeat logo-bg",class:{
                    'no-text': !_vm.isLightHeader,
                    'm-r-30': _vm.isLightHeader,
                },style:({
                    backgroundImage: `url(${_vm.logoUrl})`
                  })})]),_c('ul',{staticClass:"menus flex align-center",class:{'light-header': _vm.isLightHeader && !_vm.showDark, 'show-dark': _vm.showDark, }},[_vm._l((_vm.menus),function(menu){return [(!menu.hide)?[(!menu.isScore)?_c('li',{key:menu.id,staticClass:"pointer m-r-30 font-500 text-center hover-item",class:{ 'is-active': _vm.currentId === menu.id},on:{"click":function($event){return _vm.changeTab(menu)}}},[_c('span',{staticClass:"font-18"},[_vm._v(_vm._s(menu.text))])]):_c('li',{key:menu.id,staticClass:"pointer m-r-30 font-400 text-center score-item",class:{ 'is-active': _vm.currentId === menu.id},on:{"click":function($event){return _vm.changeTab(menu)}}},[_c('el-popover',{attrs:{"placement":"bottom","width":"400","popper-class":"score-popper","trigger":"hover"}},[_c('div',{staticClass:"body flex align-center justify-around flex-wrap"},[_vm._l((_vm.scoreMenus),function(scoreItem){return [_c('el-button',{key:scoreItem.id,staticClass:"score-button p-0 m-0 font-regular font-16",class:{
                                                'is-active': _vm.scoreActiveLeague === scoreItem.id
                                            },attrs:{"disabled":scoreItem.disabled},on:{"click":function($event){return _vm.goToScore(scoreItem)}}},[_vm._v(_vm._s(scoreItem.title))])]})],2),_c('el-button',{staticClass:"score-slot-button font-600",class:{
                                      'is-active': _vm.isScorePage
                                 },attrs:{"slot":"reference"},slot:"reference"},[_vm._v(" 即时比分 "),_c('i',{staticClass:"el-icon-caret-bottom el-icon--right"})])],1)],1)]:_vm._e()]})],2),_c('span',{staticClass:"download-app m-r-20 bg-size-100 pointer flex align-center justify-center font-500 font-18 font-medium",class:{
                                'light': _vm.isLightHeader && !_vm.showDark
                            },on:{"click":_vm.goToDownload}})]),_c('user-infos',{staticClass:"p-r-10",attrs:{"is-light-header":_vm.isLightHeader,"show-dark":_vm.showDark}}),_c('span',{staticClass:"text-button visibility-hidden p-absolute",attrs:{"id":"assistBtn"}},[_vm._v("assistBtn")])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }